import { useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import { ToastContent } from '../ToastContent';
import styles from './styles.module.css';

export const RemovalConfirmationModal = ({ promptToRemoveId, entityLabel = 'Entity', onRemove, onClose }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOpen = !!promptToRemoveId;

  if (!isOpen) return null;

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await onRemove(promptToRemoveId);
      toast(<ToastContent title={`${entityLabel} removed!`} />)
      onClose();
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <h1>{entityLabel} removal confirmation</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.bottomActions}>
            <button className={styles.cancelBtn} onClick={onClose}>
              Cancel
            </button>
            <button
              className={styles.createBtn} onClick={onSubmit}
              disabled={isLoading}
            >
              {isLoading ? <Loader small /> : 'Remove'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
