import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ComponentsListQuery, DeleteComponentQuery } from './query';
import { CreateComponentModal } from './CreateComponentModal';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ComponentIcon } from '../../assets/icons/component.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { Header } from '../../components/Header';
import { PlusFloatingButton } from '../../components/PlusFloatingButton';
import { RemovalConfirmationModal } from '../../components/RemovalConfirmationModal';
import { Sidebar } from '../../components/Sidebar';
import Table from '../../components/Table/Table';
import { formatWord } from '../../utils/formatWord';
import styles from './styles.module.css';

export const ComponentsListPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [editModalData, setEditModalData] = useState<Record<string, string>>();
  const [promptToRemoveId, setPromptToRemoveId] = useState<string>();
  const { data, loading, refetch } = useQuery(ComponentsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const [deleteComponent] = useMutation(DeleteComponentQuery);

  const rows = useMemo(
    () =>
      (data?.components?.data || []).map((component: any) => ({
        id: component.id,
        name: component.attributes?.name,
        description: component.attributes?.description,
        productsAmount: component.attributes?.products?.data?.length || 0,
        linkedProductModelsIds: component.attributes?.products?.data?.map((pm: any) => pm.id) || [],
        partsAmount: component.attributes?.part_models?.data?.length || 0,
        linkedPartModelsIds: component.attributes?.part_models?.data?.map((partModel: any) => partModel.id) || [],
        image: component.attributes?.images?.data?.[0]?.attributes.url,
        type: '—',
        status: formatWord(component.attributes?.status || '—'),
      })),
    [data]
  );

  const onEdit = (params: any) => {
    const entityData = rows?.find((row: any) => row.id === params.data.id);
    setEditModalData({ modifyType: 'edit', ...(entityData || {}) });
  };

  const onDelete = async (id: any) => {
    await deleteComponent({
      variables: { id },
    });
    refetch?.();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => {
              if (e.colDef?.field === 'actions') return;
              navigate(`/component/${e.data.id}`);
            }}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Components</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Component Name',
                flex: 1,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <ComponentIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
              },
              {
                field: 'type',
                headerName: 'Type',
                width: 200,
                filterName: 'type',
              },
              {
                field: 'status',
                headerName: 'Status',
                width: 200,
                filterName: 'status',
              },
              {
                field: 'productsAmount',
                headerName: 'Products',
                width: 200,
                filterName: 'productsAmount',
              },
              {
                field: 'partsAmount',
                headerName: 'Parts',
                width: 200,
                filterName: 'partsAmount',
              },
              {
                field: 'actions',
                headerName: '',
                width: 120,
                filterName: 'actions',
                cellRenderer: (params: any) => (
                  <div className={styles.actionsField}>
                    <EditIcon onClick={() => onEdit(params)} />
                    <CloseIcon onClick={() => setPromptToRemoveId(params.data.id)} />
                  </div>
                ),
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={[]}
            paginationData={data?.components?.meta?.pagination}
            onChangePage={setPage as any}
            hideFilters
          />
          <PlusFloatingButton onClick={() => setEditModalData({ type: 'new' })} />
          {!!editModalData && <CreateComponentModal editModalData={editModalData} onClose={() => setEditModalData(undefined)} />}
          <RemovalConfirmationModal
            promptToRemoveId={promptToRemoveId}
            entityLabel='Component'
            onRemove={onDelete}
            onClose={() => setPromptToRemoveId(undefined)}
          />
        </div>
      </div>
    </div>
  );
};
